<template>
  <div class="login-page">
    <div class="logo" @click="toindex()" title="返回首页">
      <img src="@/assets/logo.png" />
    </div>
    <div class="bg"></div>
    <div class="ikon">
      <div class="settop">
        <span class="first"><img src="@/assets/finish.png"></span>
        <p class="first_text">身份认证</p>
        <p class="complateline"></p>
        <span class="first"><img src="@/assets/finish.png"></span>
        <p class="first_text">重置密码</p>
        <p class="complateline"></p>
        <span class="first">3</span>
        <p class="first_text">完成</p>
      </div>
      <div class="reset-form">
        <img src="@/assets/prosper.png">
        <span>
          修改成功！</span>
        <b class="login" @click="to_login()">去登陆<img src="@/assets/tologin.png" class="tologin" @click="to_login()"></b>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reset-complated",
  // inject:['app'],
  data() {
    return {
      moble: "",
      idnum: "",
      isreason: "false",
      code: ""
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    to_login() {
      this.$router.push('./Login')
    },
    toindex() {
      this.$router.push('./homepage')
    },
  },

};
</script>

<style lang="less" scoped>
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 250, 1);
    z-index: -10;
  }
}

.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;

  >img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1300px;
  height: 720px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 8px;

  >img {
    width: 570px;
    height: 100%;
  }

  .settop {
    height: 40px;
    width: 930px;
    position: absolute;
    top: 100px;
    left: 200px;

    >span {
      display: inline-block;
    }

    >p {
      display: inline-block;
    }

    .first {
      width: 36px;
      height: 36px;
      text-align: center;
      background: rgba(255, 255, 255, 1);
      border: 2px solid rgba(222, 222, 222, 1);
      border-radius: 50%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(61, 49, 226, 1);
      line-height: 36px;
      margin-left: 18px;

      >img {
        width: 20px;
        height: 20px;
        margin-top: 8px;
      }
    }

    .first_text {
      width: 86px;
      height: 20px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: rgba(55, 67, 214, 1);
      line-height: 26px;
      margin: 0;
      margin-left: 18px;
    }

    .complateline {
      background: #3D31E2;
      width: 200px;
      height: 4px;
      border-radius: 1px;
      margin-left: 18px;
      margin-bottom: 5px;
    }
  }

  .reset-form {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    top: 225px;
    margin: auto;
    height: 400px;
    width: 490px;
    background-color: #fff;
    box-sizing: border-box;
    padding-left: 50px;

    >p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
    }

    >img {
      width: 378px;
      height: 140px;
    }

    >span {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      display: inline-block;
      width: 180px;
      height: 26px;
      margin-top: 55px;
      padding-left: 80px;
      line-height: 22px;
    }

    .login {
      color: #3D31E2;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      width: 150px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      display: inline-block;

      .tologin {
        width: 22px;
        height: 22px;
        position: relative;
        left: 22px;
        top: 4px;
      }
    }
  }
}</style>