<template>
  <div>
    <reseted />
  </div>
</template>

<script>
// @ is an alias to /src
import reseted from "@/components/reset-complated";

export default {
  name: "reset",
  components: {
    reseted
  }
};
</script>